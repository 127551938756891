<template>
  <b-row>
    <b-col cols="12">
      <b-form @submit.prevent>
        <b-form-group label="Choose Blog Category" label-for="blog-category">
          <v-select
            id="blog-category"
            v-if="categoriesList"
            :reduce="(category) => category.blogCategoryId"
            v-model="selectedCategory"
            :options="categoriesList"
            label="name"
            placeholder="Choose your desired category"
          >
            <template #no-options>There is no category available</template>
          </v-select>
        </b-form-group>
      </b-form>
    </b-col>
  </b-row>
</template>
<script>
import { GetAllBlogCategories } from "@/libs/Api/BlogCategory";
import { BRow, BCol, BForm, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  emits: ["getBlogCategory"],
  props: {
    defaultCategory: {
      type: [Number, String],
      required: false,
    },
  },
  mounted() {
    this.getAllCategories();
  },
  watch: {
    defaultCategory: {
      immediate: true,
      handler(val) {
        if (val != this.selectedCategory) {
          this.selectedCategory = val;
        }
      },
    },
    selectedCategory: {
      handler(val) {
        this.$emit("getBlogCategory", val);
      },
    },
  },
  data() {
    return {
      categoriesList: null,
      selectedCategory: null,
    };
  },
  methods: {
    async getAllCategories() {
      try {
        let _this = this;
        let getAllBlogCategories = new GetAllBlogCategories(_this);
        await getAllBlogCategories.fetch(function (response) {
          if (response.isSuccess) {
            _this.categoriesList = response.data.data;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  components: {
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
  },
};
</script>
